.customScrollbarContainer {
    .thumbVertical {
      width: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      opacity: 0;
      transition: opacity 300ms;
      z-index: 4;
   
      &:hover,
      &:active {
        width: 12px !important;
        left: -4px;
      }
    }

    .thumbVerticalHelpmenu {
      width: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      opacity: 100;
      transition: opacity 300ms;
      z-index: 4;
   
      &:hover,
      &:active {
        width: 12px !important;
        left: -4px;
      }
    }
   
    .thumbHorizontal {
      height: 4px;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      opacity: 0;
      transition: opacity 300ms;
      z-index: 4;
   
      &:hover,
      &:active {
        height: 12px !important;
        bottom: 4px;
      }
    }
   
    &:hover,
    &:active {
      .thumbVertical,
      .thumbHorizontal {
        opacity: 1 !important;
      }
    }
  }
  @media screen and (min-width: 2200px) {  
    .customScrollbarContainer {
      div:first-child {
        // min-width: 520px;
        overflow-x: hidden !important;
      }
    }
  }
  @media screen and (min-width: 1620px) {  
    .project-left-menu__project {
      .thumbHorizontal {
        display: none !important;
      }
    }
  }